export const COLOR_TABLE: { [key: string]: string } = {
  проповеди: '#663399',
  слова: '#0F80FF',
  новости: '#0F80FF',
  уроки: '#118002',
  встречи: '#9F8732',
  беседы: '#663399',
  стихи: '#0F80FF',
  телепередачи: '#FD8008',
  'ТВ-СПАС': '#807575',
  'ТВ-СОЮЗ': '#807575',
  'РАДИО-ВЕРА': '#717B80',
  online: '#FF0707',
  радиоэфир: '#FD8008',
  'о сердечной молитве': '#111',
  instagram: '#B72FB2',
  ВК: '#5181B8',
  'заметки-дня': '#1ac303'
}

export default function tagColorByLabel(label: string) {
  return COLOR_TABLE[label]
}